import { Link } from "react-router-dom";
function Footer()
{
    return(
        <>
        <div className='footer'>
            <div className='footer-wrapper'>
                <div className='footer-columns'>
                    <div className='footer-brand-box'>
                        <div id="brand-footer"></div>
                        <div className='socials-box'>
                            <a href="https://www.facebook.com/official.pixelplus" rel="nofollow" target="_blank"><div className="socials facebook"></div></a>
                            <a href=""><div className="socials instagram"></div></a>
                            <a href="https://www.tiktok.com/@pixelplus.official" rel="nofollow" target="_blank"><div className="socials tiktok"></div></a>
                        </div>
                        <div>Descriere pixelplus.store</div>
                    </div>
                    <div>
                        <div className='footer-contact'>
                            <span>Nog Virtual Studio SRL</span>
                            <span>CUI: <i>RO 36924320</i></span>
                            <span>Email: <i>contact[at]pixelplus.store</i></span>
                        </div>
                    </div>
                </div>

                <div className='accepted-cards footer-cards'>
                    <div className='card visa'></div>
                    <div className='card mastercard'></div>
                    <div className='card americanexpress'></div>
                    <div className='card unionpay'></div>
                    <div className='card applepay'></div>
                    <div className='card googlepay'></div>
                </div>
                <div className='anpc-box'>
                    <a href="https://anpc.ro/ce-este-sal/" target="_blank" rel="nofollow"><div className='anpc-sal'></div></a>
                    <a href="https://ec.europa.eu/consumers/odr/main/index.cfm?event=main.home2.show&lng=RO" target="_blanl" rel="nofollow"><div className='anpc-sol'></div></a>
                </div>
                <div className='footer-l-box'>
                    <Link to='/'>Prima pagină</Link>
                    <Link to='/termeni-si-conditii'>Termeni și condiții</Link>
                    <Link to='/politici-de-confidentialitate'>Politica de confidențialitate</Link>
                    <Link to='/politici-livrare'>Politica de livrare</Link>
                    <Link to='/politici-retur'>Politica de retur</Link>
                    <Link to='/contact'>Contact</Link>
                </div>
                <div className='copyright'>
                    &copy; 2024 Pixelplus.store
                </div>
            </div>
        </div>
        </>
    )
}

export default Footer;