import Button from 'react-bootstrap/Button';
import {CartContext} from "./CartContext";
import { useContext } from 'react';
import { getProductData } from './ProductsList';
import product1 from './store/placeholder.png';

function YourCart(props) {
    const id = props.id;
    const quantity = props.quantity;
    const cart = useContext(CartContext);
    const product = getProductData(id);

    return(
        <>
        <div className='cartBox'>
            <div className='cart_1'>
                <div className='cartImage'>
                    <img alt={product.title} src={product1}/>
                </div>
                <div className='cartTitle'>
                    <span>{product.title}</span>
                    <div className='cartPrice'>
                        {product.storeprice},00 RON
                    </div>
                </div>
            </div>
            <div className='cartQuantity'>
                    <div className='quantitySelector'>
                        <button onClick={() => cart.removeOneFromCart(id)} disabled={quantity <= 1} className={quantity <= 1 ? '' : 'decreaseQuantity'}> - </button>
                            <span>{quantity}</span>
                        <button onClick={() => cart.addOneToCart(id, product.product_id,product.title, product.storeprice,product.stock)} className='btn-primary increaseQuantity'> + </button>
                    </div>
                    <button onClick={() => cart.deleteFromCart(id)}><div id="trash"></div></button>
            </div>
            <div className='productTotal'>
            <span className='cartPriceTxt'>Preț:</span> {(quantity * product.storeprice).toFixed(2)} lei
            </div>
        </div>
        <hr></hr>
        </>
    )
}

export default YourCart;