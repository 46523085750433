import {Button, Modal} from 'react-bootstrap';
import {useContext, useState} from 'react';
import { CartContext } from './CartContext';
import YourCart from './YourCart';
import { useNavigate, Link} from 'react-router-dom';
function Navbar(){
    const [show, setShow] = useState(false);
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);
    const navigateTo = useNavigate();
    const cart = useContext(CartContext);
    const productsCount = cart.items.reduce((sum, product) => sum + product.quantity, 0);

    const placeTheOrder = () => {
        navigateTo("/placeorder");
        handleClose();
    }
    const continueShopping = () => {
        navigateTo("/");
        handleClose();
    }
    
    return(
        <>
        <div className='navbar'>
            <Link to="/"><div id="brandlogo"></div></Link>
            <div id="cartbox">
                <Button className='btn-cart btn-bd-primary' onClick={handleShow}>
                <div id="shopping-cart"></div>
                    {productsCount > 0 ?
                        <span className="position-absolute top-0 start-100 translate-middle badge rounded-pill bg-success bg-badge">
                            <span>{productsCount}</span>
                        </span>
                        :
                        <></>
                    }
                </Button>
            </div>
        </div>

        <Modal className='modal-xl' show={show} onHide={handleClose}>
                <Modal.Header closeButton>
                    <Modal.Title>Coșul tău</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                {productsCount > 0 ?
                <>
                    <div className='cartLegend'>
                        <div>PRODUS</div>
                        <div>CANTITATE</div>
                        <div>PREȚ</div>
                    </div>
                    <hr className='hr'></hr>
                    {cart.items.map((currentProduct, idx) => (
                        <YourCart key={idx} id={currentProduct.id} quantity={currentProduct.quantity}></YourCart>
                    ))}
                    <div className='finalPriceBox'>
                        <div>
                            <span>Preț total </span> <span>{cart.getTotalCost().toFixed(2)} lei</span>
                        </div>
                        <Button variant="success purchase-btn" onClick={placeTheOrder}>
                                    Finalizează comanda
                        </Button>
                    </div>
                </>
                :
                <div className='emptyCart'>
                    <div>Coșul tău de cumpărături este gol</div>
                    <div className='cart-continue'>
                        <Button onClick={continueShopping} className="btn btn-primary" type="button">
                                Continuă cumpărăturile
                        </Button>
                    </div>
                </div>
                }
            </Modal.Body>
        </Modal>
        </>
    );
}

export default Navbar;