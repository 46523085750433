import { useEffect,useState } from 'react';
import { FetchProducts, getProductData } from './ProductsList';

import product1 from './store/placeholder.png';

function YourCart(props) {
    const id = props.id;
    const quantity = props.quantity;
    const productData = getProductData(id);
    
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);

    useEffect(() => {
        const fetchProducts = async () => {
            setLoading(true);
            try {
                await FetchProducts();
            } catch (err) {
                setError(true);
            }
            setLoading(false);
        };
        fetchProducts();
    }, []);
    
    if (loading) {
        return '';
    }
    if (error) {
        return '';
    }
    return(
        <>
        <div className='cartBox'>
            <div className='cartImage'>
                <img alt={productData.title} src={product1}/>
            </div>
            <div className='cartTitle'>
                <span>{productData.title}</span>
                <span>{quantity} BUC</span>
            </div>
            <div className='productTotal'>
                {(quantity * productData.storeprice).toFixed(2)} lei
            </div>
        </div>
        </>
    )
}

export default YourCart;